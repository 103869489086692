import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { iMessageLex } from 'src/app/core/models/iMessageLex';
import { iSettings } from 'src/app/core/models/iSettings';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AwsLexService {

    constructor(private readonly httpClient: HttpClient) { }

    async sendLexData(MessageLex: iMessageLex): Promise<any> {
        const _result = await this.httpClient.post(environment.URL, MessageLex);
        return _result;
    }

    async sendLex2(Settings: iSettings) {
        const result$ = this.httpClient.post(environment.URL, JSON.stringify(Settings));
        const _data = await lastValueFrom(result$);
        return _data;
    }
}
