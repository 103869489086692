import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    userLang: any;
    constructor() {
        this.userLang = localStorage.getItem('lang');
        console.log(this.userLang)
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        // const isApiUrl = request.url.startsWith(environment.URL);
        // request = request.clone({
        //         setHeaders: {
        //             Authorization: `Bearer ${token}`,
        //         },
        //     });

        request = request.clone({
            setHeaders: {
                //'x-api-key': environment.API_LEX,
                'x-organon-token': environment.X_ORGANON_TOKEN,
                'x-organon-lang': `${localStorage.getItem('lang')}`,
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': 'Content-Type',
                'Access-Control-Allow-Methods': 'POST'
            },
        });

        return next.handle(request);
    }
}
