// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_LEX: 'Ly5eq7M09L9v7VkpMbTTw8L5MDEw5wsy81vKcqid',
  X_ORGANON_TOKEN: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiYWFhIn0.GABU_06cycKVVJrndu4oYZ4ky8D_vuwqMZfnbM1SVuw',
  URL: 'https://pw4ccdgjoyp2m7pjnwurftudp40ydzty.lambda-url.us-east-1.on.aws',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
